.registerMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.element {
  margin-top: 16px;
}

.registerPageH1 {
  font-family: "SatoshiMedium";
  color: #f6f6f6;
  font-size: 48px;
  text-align: center;

  width: 425px;
}

.input {
  display: block;
  font-family: "Satoshi";
  width: 417px;
  height: 47px;
  font-size: 20px;
}

.inputDescription {
  margin: 4px 0 0 0;
  font-size: 12px;
  color: rgba(246, 246, 246, 0.5);
}

.submitButton {
  border-radius: 14px;
  height: 60px;
  width: 172px;
  background-color: #45d56d;
  font-family: "Satoshi";
  font-size: 20px;
  color: #000000;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.submitButton:hover {
  transition: 0.3s ease;
  box-shadow: 1px 1px 4px 2px rgba(69, 213, 109, 0.5);
  background-color: #45d56d;
  /* background-color: #9cf9b7; */
}
.submitButton:active {
  background-color: #2aac4f;
}

.registerPageGridContainer {
  display: grid;
  width: 417px;
  /* gap: 16px; */
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 172px 1fr 1fr;
}

.registerButtonSubmit {
  grid-row: 1/3;
  grid-column: 1/2;
}

.registerAgreeTerms {
  padding-left: 16px;
  font-size: 12px;
  color: rgba(246, 246, 246, 0.7);
  grid-row: 1/1;
  grid-column: 2/4;
  margin: 0;
}

.haveAnAccount {
  padding-left: 16px;
  grid-row: 2/2;
  grid-column: 2/4;
  margin: 0px;
  font-size: 16px;
  align-self: end;
  padding-bottom: 4px;
}

.smallError {
  color: #ff693a;
  font-size: 16px;
  text-align: center;
}

a {
  color: #f6f6f6;
}

@media only screen and (max-width: 600px) {
  .registerPageH1 {
    margin: 0px;
  }

  .registerPageGridContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    /* gap: 8px; */

    /* grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr; */
  }

  .registerMainContainer {
    width: 347px;
  }

  .registerButtonSubmit {
    /* grid-row: 1;
    grid-column: 1; */
  }

  .registerAgreeTerms {
    align-self: center;
    margin-top: 8px;
  }

  .haveAnAccount {
    align-self: center;
    margin-top: 8px;
  }
}
