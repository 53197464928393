.landing h1 {
  background: radial-gradient(
    circle,
    rgba(203, 203, 203, 0.66) 12%,
    rgba(223, 223, 223, 1) 22%,
    rgba(246, 246, 246, 1) 33%,
    rgba(246, 246, 246, 0.74) 66%,
    rgba(246, 246, 246, 1) 88%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "SatoshiBlack";
  margin: 0;
  /* margin-left: 80px; */
  padding: 0;
  font-size: 64px;
}

.landing h1.landingMainH1 {
  margin-left: 80px;
}
.landing h2.landingMainH2 {
  margin-left: 80px;
}

.landing h2 {
  color: rgba(265, 265, 265, 0.65);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: "SatoshiLight";
  font-weight: 100;
  margin: 0;
  margin-left: 80px;
  padding: 0;
  font-size: 48px;
}

.welcomeSection {
  height: 100vh; /* Full viewport height */
  width: 100vw;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center elements vertically in the container */
  align-items: flex-start; /* Center elements horizontally */
  padding: 0;
  padding-top: 50px;
  margin: 0;
}

.landingMainImg {
  height: 100vh; /* Full viewport height */
  width: 100%;
  position: absolute;
  /* display: flex; */
  background-image: url("../assets/images/MainBackgroundImage.png"); /* Set the background image */
  background-size: 60%; /* Ensure background covers the entire container */
  background-position: right; /* Center the background image */
  background-position: calc(100% - 100px) center;
  background-repeat: no-repeat; /* Prevent background repetition */
  z-index: -100;
}

.landing h1.landingSectionBoldTitle {
  background: radial-gradient(
    circle,
    rgba(246, 246, 246, 0.6) 25%,
    rgba(246, 246, 246, 0.8) 58%,
    rgba(246, 246, 246, 1) 84%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "SatoshiBlack";
  font-size: 48px;
  font-family: "SatoshiBlack";
  margin: -20px 0 0 0;
}
.landing h1.landingSectionBoldTitlePrice {
  background: radial-gradient(
    circle,
    rgba(246, 246, 246, 0.9) 80%,
    rgba(246, 246, 246, 0.8) 50%,
    rgba(246, 246, 246, 0.6) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "SatoshiBlack";
  font-size: 48px;
  margin: -20px 0 0 0;
}

.landing p.landingSectionTwoP {
  font-family: "Satoshi";
  font-size: 22px;
  max-width: 80%;
}

.landingPageMainContainer {
  padding: 0 0 0 14vw;
  /* max-width: 70%; */
}

.landing h3.landingMainH3 {
  font-size: 24px;
  font-family: "SatoshiBold";
  min-width: 300px;
  margin-top: 120px;
  margin-bottom: -24px;
}

.landingChartDBImg {
  /* height: 100%; */
  width: 100%;
  border-radius: 12px;
}

.gridLayoutChartContainer {
  display: grid;
  grid-template-columns: 3fr minmax(400px, 3fr);
  grid-gap: 0 125px;
  grid-template-rows: 1fr;
  /* width: 90%; */
}

.landingListChartDBIcon {
  height: 24px;
  width: 24px;
  display: block;
}

.landingListChartDB {
  list-style: none;
  /* width: 100%; */
}

.landingListChartDBElement {
  display: flex;
  align-items: center;
}

.landingListChartDBText {
  font-size: 24px;
  margin: 12px 0 12px 8px;
}

ul.landingListChartDB {
  padding: 0;
}

.landingListVariety {
  list-style: none;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

ul.landingListVariety {
  padding: 0;
}

.landingListVarietyElement {
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 200px;
}

.gridLayoutVarietyContainer {
  display: grid;
  grid-template-columns: 2fr minmax(400px, 3fr);
  grid-gap: 0 75px;
  grid-template-rows: 1fr;
  padding-right: 200px;
  margin-top: 64px;
}

.varietyImage {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Controls fade-in and fade-out timing */
  max-width: 100%;
  height: auto;
  top: 0;
  left: 0;
  margin-top: -40px;
}

.varietyImage.active {
  opacity: 1;
}

.landingMockupImg {
  position: relative;
  height: 400px; /* Adjust to your image height */
  width: 600px; /* Adjust to your image width */
  margin-top: -75px;
}

.landingListVarietyIcon {
  height: 50px;
  width: 50px;
  display: block;
}

.landingListVarietyButton {
  display: flex;
  align-items: center;
  gap: 0 16px;
  padding: 15px 25px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-radius 0.3s ease;
}

.landingListVarietyButton.active {
  animation: pulse 1s infinite; /* Pulsing effect */
  border-radius: 15px; /* Border radius only when active */
  box-shadow: 0 0 10px rgba(255, 110, 196, 0.7),
    0 0 20px rgba(120, 115, 245, 0.7), 0 0 30px rgba(0, 212, 255, 0.7),
    0 0 40px rgba(150, 255, 0, 0.7);
  transform: scale(1.05); /* Slight scaling on active state */
  border: 3px solid transparent;
}

.landingListVarietyButton:hover {
  transform: scale(1.03);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.priceSectionContainer {
  display: flex;
  gap: 1rem 10rem;
  align-items: baseline;
  flex-wrap: wrap;
}

.priceDescriptionP {
  width: 620px;
  max-width: 80vw;
  font-size: 20px;
  /* flex-grow: 1; */
}

.priceSectionConditionsContainer {
  display: flex;
  flex-direction: column;
}

.priceSectionConditionsItemH {
  opacity: 30%;
  font-size: 20px;
}

.priceSectionConditionsItemP {
  font-size: 20px;
}

/* Pulse animation for the active button */
@keyframes pulse {
  0% {
    box-shadow: 0 0 10px rgba(255, 110, 196, 0.7),
      0 0 20px rgba(120, 115, 245, 0.7), 0 0 30px rgba(0, 212, 255, 0.7),
      0 0 40px rgba(150, 255, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 110, 196, 0.7),
      0 0 30px rgba(120, 115, 245, 0.7), 0 0 40px rgba(0, 212, 255, 0.7),
      0 0 50px rgba(150, 255, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 110, 196, 0.7),
      0 0 20px rgba(120, 115, 245, 0.7), 0 0 30px rgba(0, 212, 255, 0.7),
      0 0 40px rgba(150, 255, 0, 0.7);
  }
}

.landingVarietyListP {
  font-size: 20px;
}

.landingFreeChargeP {
  color: #f6f6f6;
  opacity: 50%;
  margin-top: 8px;
}

.landingThirdTitleH3 {
  font-size: 24px;
  font-family: "SatoshiBold";
  min-width: 300px;
  margin-top: 120px;
  margin-bottom: 0px;
}

.gridOptionsContainer {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 0;
}

.gridOptionsItem {
  display: flex;
  align-items: center;
}

.landingListOptionsP {
  font-size: 18px;
  margin-left: 8px;
}

.landingListOptionsIcon {
  height: 24px;
  width: 24px;
  display: block;
}

.landingComingSoon {
  font-family: "SatoshiBlack";
  opacity: 50%;
  font-size: 24px;
  margin-top: 64px;
  margin-bottom: 24px;
}

.landingBenefitsConteiner {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 507px;
}

.landingBenefitsLine {
  font-size: 20px;
  margin-bottom: 22px;
  margin-top: 26px;
}

.landingBenefitsDevider {
  align-self: center;
  width: 110%;
  height: 0.5px;
  margin-left: 0;
  background: rgb(246, 246, 246);
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0) 0%,
    rgba(246, 246, 246, 1) 50%,
    rgba(246, 246, 246, 0) 100%
  );
}

.landingBenefitsDevider:last-child {
  background: none;
}

/* .backgroundSquaresImg {
  position: absolute;
  top: 1800px;
  z-index: -100;
  width: 1728px;
  height: 3476px;
  left: 0;
  overflow: hidden;
} */

.backgroundSquaresContainer {
  position: absolute;
  z-index: -200;
  left: 0;
  top: 1500px;
  width: 100vw;
  overflow: hidden;
}

.backgroundSquaresImg {
  /* width: 100%;
  height: 100%; */
}

.progressContactSectionContainer {
  display: flex;
}

.progressSectionContainer {
  /* width: 60vw; */
  flex-direction: row;
  /* max-width: 80vw; */
}

.contactSectionContainer {
  margin: 50px auto;
}

.landing h1.landingSectionBoldTitle {
  margin: 40px auto;
}

.footerContainer {
  margin-top: 24px;
  width: 100vw;
  padding: 24px 0;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.footerDisclaimerP {
  opacity: 50%;
  font-size: 16px;
}

.footerLogo {
  display: block;
  height: 90px;
  width: 119px;
  align-items: center;
}

@media (max-width: 1200px) {
  .landingMainImg {
    background-size: 80%;
    background-position: right;
    margin-top: 0px;
  }
}

@media (max-width: 1032px) {
  .landingListVariety {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .gridLayoutVarietyContainer {
    grid-template-columns: 100%;
    padding: 0px;
  }

  .landingMockupImg {
    width: 50%;
    /* margin: auto; */
    margin-top: 80px;
    left: 50%;
    transform: translate(-50%);
  }

  .landingThirdTitleH3 {
    margin-top: 24px;
  }

  .progressContactSectionContainer {
    flex-direction: column;
    margin-left: -10vw;
  }

  .landingMainImg {
    background-size: 70%;
    background-position: right;
    margin-top: -80px;
    margin-left: -10px;
  }
}

/* Tablets */
@media only screen and (max-width: 931px) {
  .gridLayoutChartContainer {
    grid-gap: 50px 0px;
    grid-template-columns: 100%;
    /* grid-template-rows: 1fr; */
  }
  .landingChartDBImg {
    width: 90%;
  }
}

/* Phones */
@media only screen and (max-width: 480px) {
  .welcomeSection {
    /* justify-content: start; */
  }
  .landing h1 {
    font-size: 32px;
    margin-left: 64px;
    /* margin-top: 250px; */
  }
  .landing h2 {
    font-size: 30px;
    margin-left: 64px;
  }
  .landingMainImg {
    background-size: 150%;
    background-position: left;
    margin-top: -80px;
    margin-left: -10px;
  }

  .landing h1.landingSectionBoldTitle {
    margin: -150px 0 0 -4px;
    /* font-size: 36px; */
  }
  .landing h1.landingSectionBoldTitlePrice {
    background: radial-gradient(
      circle,
      rgba(246, 246, 246, 0.9) 25%,
      rgba(246, 246, 246, 0.8) 58%,
      rgba(246, 246, 246, 0.3) 84%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: "SatoshiBlack";
    font-size: 48px;
    margin: -150px 0 0 -4px;
    /* font-size: 36px; */
  }

  .landing p.landingSectionTwoP {
    font-size: 18px;
    max-width: 324px;
  }

  .landingPageMainContainer {
    padding: 0 0 0 44px;
    max-width: 100%;
  }

  .gridLayoutChartContainer {
    grid-gap: 24px 0px;
  }

  .landingChartDBImg {
    width: 90%;
  }

  .landingListChartDBIcon {
    height: 20px;
    width: 20px;
    display: block;
  }

  .landingListChartDBText {
    font-size: 18px;
    margin: 12px 0 12px 8px;
  }

  .landingListChartDB {
    /* width: 90%; */
  }

  .gridLayoutVarietyContainer {
    grid-template-columns: 100%;
    padding: 0px;
  }

  .landingMockupImg {
    width: 350px;
    margin-top: 0px;
  }

  .landingListVariety {
    grid-row-start: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -10vw;
  }
  .landingMockupImg {
    grid-row-start: 1;
    margin-bottom: -40px;
  }

  .landing h3.landingMainH3 {
    font-size: 24px;
    font-family: "SatoshiBold";
    width: 300px;
    margin-top: 80px;
    margin-bottom: -40px;
  }

  .varietyImage {
    margin-top: 0px;
  }

  .gridOptionsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .gridOptionsItem {
    display: flex;
    align-items: center;
  }

  .landingListOptionsP {
    font-size: 18px;
    margin-left: 8px;
  }

  .landingListOptionsIcon {
    height: 24px;
    width: 24px;
    display: block;
  }

  .landingBenefitsDevider {
    margin-left: -5vw;
    width: 100%;
  }

  .landingBenefitsLine {
    font-size: 18px;
  }

  .progressContactSectionContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .progressSectionContainer {
    max-width: 80vw;
  }

  .contactSectionContainer {
    margin: 0;
  }

  .landing h1.landingSectionBoldTitle {
    margin-top: 0px;
  }

  .footerContainer {
    margin-top: 64px;
  }

  .footerDisclaimerP {
    font-size: 12px;
  }
}

/* PROGRESS SECTION */
/* General styles */
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 50px auto 50px auto;
}

.progress-title {
  font-size: 2.5rem;
  color: #ccc;
  margin-bottom: 40px;
}

/* The progress line and its steps */
.progress-line {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.step-wrapper {
  position: relative;
}

.progress-step {
  display: flex;
  align-items: flex-start; /* Aligning items vertically */
  margin-bottom: 40px; /* Space between circles */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.progress-step.active .progress-circle {
  background-color: #00c853;
  border-color: #00c853;
  color: black; /* Black number in active circle */
  transition: background-color 0.8s ease;
}

.progress-circle.completed {
  background-color: #00c853 !important;
  border-color: #00c853 !important;
  color: #f6f6f6 !important;
}

.progress-circle {
  width: 50px; /* Circle size */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ccc;
  margin-right: 15px;
  transition: background-color 1s ease, border-color 1s ease;
  flex-shrink: 0; /* Prevent shrinking */
  min-width: 50px; /* Prevent shrinking below this size */
  min-height: 50px; /* Prevent shrinking below this size */
}

/* Checkmark for completed steps */
.progress-step.active .progress-circle span {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8); /* 50% opacity for completed steps */
}

/* Circle gradient for the next step */
.progress-step.active + .progress-step .progress-circle {
  background: linear-gradient(90deg, #00c853 0%, #ccc 100%);
  border-color: transparent;
}

.step-content {
  display: flex;
  flex-direction: column;
}

.step-title {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.5); /* 50% opacity for inactive steps */
  margin-bottom: 10px;
  margin-top: 12px;
  transition: color 0.3s;
}

.step-description {
  font-size: 1rem;
  color: #ccc;
  margin-left: 0; /* No margin-left since it's under the title */
  max-width: 400px;
  margin-top: 5px;
}

/* Highlighting the active title */
.progress-step.active .step-title {
  color: #fff; /* Full white for active title */
  font-family: "SatoshiMedium";
}

/* Grey for previous step titles */
.progress-step.completed .step-title {
  color: grey;
}

/* Keep green color for previous step circles */
.progress-step.completed .progress-circle {
  background-color: #00c853;
  border-color: #00c853;
  color: white; /* Adjust circle text color if needed */
}

/* Styling for steps that are inactive */
.progress-step:not(.active) .progress-circle {
  background-color: #131313;
  border-color: #555;
  color: #555;
}

.progress-step:not(.active) .step-title {
  color: rgba(255, 255, 255, 0.5); /* 50% opacity for inactive titles */
}

.progress-step:not(.active) .step-description {
  display: none;
}

/* The connector line between steps */
.progress-line-connector {
  position: absolute;
  top: 50px; /* Adjusted to connect circles */
  left: 26px; /* Aligned with larger circles */
  width: 2px;
  height: 90px; /* Lengthened line between steps */
  background: linear-gradient(180deg, #333 0%, #555 100%);
  transition: background 0.8s ease; /* Line animation */
  z-index: -1;
}

.progress-line-connector.active {
  background: linear-gradient(180deg, #00c853 0%, #555 70%);
  transition: background 0.8s ease; /* Green-to-gray gradient for current to next step */
}

.progress-line-connector.completed {
  background: #00c853; /* Solid green for completed steps */
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .progress-container {
    padding: 0;
  }

  .step-description {
    font-size: 0.9rem;
  }

  .progress-title {
    font-size: 2rem;
  }

  .progress-line-connector {
    height: 120px;
  }

  .step-wrapper:last-child .progress-line-connector {
    height: 40px; /* Make the last line shorter */
  }
}
