@font-face {
  font-family: "SatoshiBlack";
  src: local("SatoshiBlack"),
    url("./assets/fonts/Satoshi-Black.ttf") format("truetype");
}
@font-face {
  font-family: "SatoshiBold";
  src: local("SatoshiBold"),
    url("./assets/fonts/Satoshi-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SatoshiMedium";
  src: local("SatoshiMedium"),
    url("./assets/fonts/Satoshi-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SatoshiLight";
  src: local("SatoshiLight"),
    url("./assets/fonts/Satoshi-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("./assets/fonts/Satoshi-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SatoshiItalic";
  src: local("SatoshiItalic"),
    url("./assets/fonts/Satoshi-Italic.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f6f6f6;
  /* background: linear-gradient(292deg, #22283a, #131313); */
}

html {
  /* background-image: linear-gradient(292deg, #22283a, #131313); */
  background-color: #131313;
}

code {
  font-family: "Satoshi", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
