.notFoundH1 {
  font-size: 100px;
  text-align: center;
  margin: 0;
}

.notFoundLink {
  font-size: 100px;
  text-align: center;
}

.notFoundMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/images/blackPutin.jpg");
  /* background-image: url("../assets/images/MainBackgroundImage.png");  */
  background-size: 100%;
  /* background-position: right; */
  /* background-position: calc(100% - 100px) center; */
  background-repeat: no-repeat;
  z-index: -100;
}

@media (max-width: 700px) {
  .notFoundMainContainer {
    background-size: 222%;
    background-position: center;
  }

  .notFoundH1 {
    font-size: 64px;
  }
  .notFoundLink {
    font-size: 48px;
  }
}
