.containerInput,
.entryArea,
.labelLine {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", "Roboto";
  box-sizing: border-box;
}

.inputIconSvg {
  position: absolute;
  margin: 0;
  height: 47px;
  left: 16px;
}

.containerInput {
  width: 372px;
  margin-top: 32px;
}

.entryArea {
  position: relative;
  height: 47px;
  line-height: 47px;
}

.customInput {
  font-size: 20px;
  position: absolute;
  width: 100%;
  outline: none;
  padding: 0;
  /* padding: 0 45px; */
  padding-left: 45px;
  line-height: 47px;
  border-radius: 5px;
  border: 1px solid #f6f6f6;
  background: transparent;
  transition: 0.1s ease;
  z-index: 1111;
}

/* THIS LOGIC PREVENTS AUTOCHANGING OF STYLING OF AUTOFILL */
.customInput:-webkit-autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus,
.customInput:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

/* Small movable text (Textfield name / placeholder) */
.labelLine {
  position: absolute;
  font-size: 20px;
  color: #f6f6f6;
  padding: 0 25px;
  margin: 0 20px;
  transition: 0.2s ease;
  font-family: "Satoshi";
}

.customInput::selection {
  color: #f6f6f6;
  background-color: #000;
}

.customInput:focus,
.customInput:valid {
  color: #f6f6f6;
  border: 2px solid #f6f6f6;
}

.customInput:focus + .labelLine,
.customInput:valid + .labelLine {
  color: rgba(246, 246, 246, 0.7);
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  transform: translate(-32px, -25px);
  font-size: 12px;
  z-index: 1111;
  font-family: "SatoshiBold";
}

@media only screen and (max-width: 600px) {
  .containerInput {
    width: 300px;
    margin-top: 32px;
  }
}
