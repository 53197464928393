.mgt8 {
  margin-top: 8px;
}

.mgt16 {
  margin-top: 16px;
}
.mgt24 {
  margin-top: 24px;
}
.mgt32 {
  margin-top: 32px;
}
.mgt40 {
  margin-top: 40px;
}
