.loginPageH1 {
  font-family: "SatoshiMedium";
  color: #f6f6f6;
  font-size: 48px;
  text-align: center;

  width: 425px;
}

.loginPageGridContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 0px;
}

.loginButtonSubmit {
  margin-top: 32px;
}
.resetPasswordBtnSubmit {
  margin-top: 32px;
  display: block;
}

.dontHaveAccount {
  font-size: 16px;
  color: rgba(246, 246, 246, 0.5);
  margin: 0;
  text-align: center;
}

.forgotPasswordLink {
  display: block;
  width: 419px;
  text-align: right;
  color: rgba(246, 246, 246, 0.5);
  margin-top: 4px;
}

.resetPasswordMainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loginMainContainer {
  width: 419px;
}

.resetPasswordPageH1 {
  font-family: "SatoshiMedium";
  color: #f6f6f6;
  font-size: 48px;
  text-align: center;
  width: 425px;
  margin: 0;
}

.additionalInfoPageH1 {
  font-family: "SatoshiMedium";
  color: #f6f6f6;
  font-size: 48px;
  text-align: center;
  width: 600px;
  margin: 0;
}

.smallSuccessMessage {
  color: #3cd253;
  font-size: 16px;
  text-align: center;
}

.middleText {
  text-align: center;
}

.secondaryBtn {
  border-radius: 14px;
  height: 56px;
  width: 150px;
  background-color: transparent;
  font-family: "Satoshi";
  font-size: 20px;
  color: #fff;
  border: none;
  margin-left: auto;
  margin-right: auto;
}

.signOutBtn {
  /* text-align: end;
  padding-right: 32px; */
  font-size: 18px;
  margin-top: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #000;
}

@media only screen and (max-width: 600px) {
  .registerPageGridContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .loginMainContainer {
    width: 347px;
  }

  .forgotPasswordLink {
    width: auto;
  }

  .resetPasswordPageH1 {
    font-size: 2rem;
    font-family: "Satoshi";
  }
  .additionalInfoPageH1 {
    width: 100%;
    font-size: 32px;
    font-family: "Satoshi";
  }
}

/* TODO add another breakpoint for smaller devices and make input fields responsive */
