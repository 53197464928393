.headerMain {
  box-sizing: border-box;
  margin: 0;
  background-color: transparent;
  position: absolute;
  width: 100%;
  font-family: "Satoshi";
  font-size: 20px;
}

.nav {
  color: var(--main-color-white);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 1rem 5rem;
}

.navbarLink {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.25rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.25rem;
}

.nav p {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.25rem;
}

.nav li:active {
  /* color: red; */
}

.nav li a:hover {
  color: #898989;
}

.nav .navbarLink:hover {
  color: #898989;
}

.nav .navbarLink {
  display: flex;
}

/* Phones */
@media only screen and (max-width: 480px) {
  .nav .navbarLink {
    display: none;
  }

  .nav ul {
    gap: 1rem;
  }

  .nav {
    padding: 25px 0 0 0;
    justify-content: space-evenly;
  }

  .headerMain {
    font-size: 18px;
  }
}
