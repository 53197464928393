.submitContactButton {
  border-radius: 14px;
  height: 60px;
  width: 200px;
  background-color: #f79f37;
  font-family: "Satoshi";
  font-size: 20px;
  color: #000000;
  border: none;
  /* margin-top: 32px; */
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s ease;
}

.submitContactButton:hover {
  transition: 0.3s ease;
  box-shadow: 1px 1px 4px 2px rgba(247, 159, 55, 1);
  background-color: #d38527;
  /* background-color: #9cf9b7; */
}
.submitContactButton:active {
  background-color: #8e5817;
  box-shadow: 1px 1px 4px 2px rgba(142, 88, 23, 1);
}

.contactUsButtonContainer {
  display: flex;
  margin-top: 32px;
  flex-direction: row;
}

.contactUsDisclaimerP {
  margin: 0 0 0 24px;
  color: #dbd3cf;
  opacity: 50%;
  font-size: 12px;
}

/* 7482626879:AAHBzekCW1CgS_2rbcTSJeVw1MjLX7CQR2s */
